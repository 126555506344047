/********radio checkbox css start**************/
.switchs { width: 36px; height: 20px; background-color: #FF6969; border-radius: 30px; display: flex; align-items: center; padding: 4px; cursor: pointer; transition: background-color 0.3s ease; }
.switchs.onSw {background-color: #3d5ee1; }
.sliderSw {width: 12px; height: 12px; background-color: #fff; border-radius: 50%; transition: transform 0.3s ease; }
.switchs.onSw .sliderSw{ background: #fff;}
.switchs.onSw .sliderSw {transform: translateX(16px); }
.switchs .slider {margin-top: 2px; }
/********radio checkbox css end**************/

.sidebarPopup{ width: 500px;  position: fixed; right: 0px; top: 0px; height: 100vh; z-index: 20;   right: -100%;  animation: slideInRight 0.6s ease-in forwards; }
.sidebarPopup::after{ content: ""; position: fixed; left: 0px; top:0px; background: #000; width: 100%; height: 100%; z-index: 1; opacity: 0.5;}
.sidebarPopupin{ width: 100%; height: 100vh; background: #fff; z-index: 2; position: relative;}

.closed{ font-size: 35px; background: none; display: flex; align-items: center; justify-content: center; position: absolute; left: -45px; top:0px; top: 0px; background: #fff; padding: 5px 5px 5px 5px;  border-radius: 0 0 0 22px; }
.sidebarPopupin{ padding: 20px; }
.sidebarPopupin h2{ padding: 0 0 20px 0; font-size: 18px;}
.modalActive{ overflow: hidden; }
@keyframes slideInRight {
    from {
      right: -100%; 
    }
    to {
      right: 0; 
    }
  }

  
  
  .flexCenter{ display: flex; align-items: center; justify-content: center;}

  /********SkeletonLoading css end**************/
.skeletonLoadingMain{ width: 100%; }
.skeletonLoading{ width: 100%; height: 23px; background-color: #f0f0f0; overflow: hidden; position: relative; border-radius: 6px; margin: 0 0 4px 0;}
.skeletonLoading span { display: block; width: 100%; height: 100%; background: linear-gradient(90deg, transparent 25%, #e0e0e0 50%, transparent 75%); background-size: 200% 100%; animation: loading 2s infinite;}
.skeletonLoading:first-child{ width: 20%; margin-bottom: 8px;}
.skeletonLoading:last-child{ width: 90%; margin-top: 8px;}
.skeletonLoadingMainIns{ height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.skeletonLoadingMainIns .skeletonLoading{ width: 165px; height: 165px; padding: 20px; border-radius: 50%; display: flex; align-items: center; justify-content:center;}
.skeletonLoadingMainIns .skeletonLoading img{ position: relative; z-index: 1; width: 130px; height: 130px;}
.skeletonLoadingMainIns .skeletonLoader{ position: absolute; left: 0px; top:0px; width: 100%; height: 100%;}

@keyframes loading {0% {
  background-position: 200% 0;
}
100% {
  background-position: -200% 0;
}}
/********SkeletonLoading css end**************/

/********SkeletonLoading css start**************/
.dataNotFoundMain{ background: rgb(61, 94, 225, 0.4); border: 1px solid #f8f8f8; padding: 15px; text-align: center; margin-top: 10px;  text-align: center; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; flex-direction: column; border-radius: 8px;}
.dataNotFoundMain label{ width: 250px; display: block; margin: 20px 0 0 0; }
.dataNotFoundMain h3{ font-weight: 600; color: #ff5315; margin-top: 10px; }
/********SkeletonLoading css end**************/

.skeletonNotFound{ width: 100%; }
.unlockBox{   }
.unlockBox h3{ padding: 0px; background: #ff5315; width: 100%; height: 45px; display: flex; align-items: center; justify-content: center; color: #fff;}
.unlockBox p{ font-size: 13px; padding-bottom: 10px; }
.unlockBox section{ max-width: 600px; border-radius: 0px; display: flex; align-items: center; justify-content: center; flex-direction: column; background: #F5F5F5; padding: 0px; margin-top: 150px; border-radius: 4px; overflow: hidden;}
.unlockBox aside{ width: 100%; padding: 20px 20px 20px 100px; display: flex; align-items: flex-start; justify-content:flex-start; flex-direction: column; position: relative;}
.unlockBox label{ width: 60px; position: absolute; left: 20px; top:20px;}
