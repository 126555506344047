.buttonGlobalMain {
    width: 100%;
}

.buttonGlobalMain button {
    width: 100%;
    height: 44px;
    background: #2A2D32;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 0px;
    padding: 0 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonGlobalMain button img {
    margin-left: 8px;
}

.buttonGlobalMain button:hover {
    background: #2A2D32;
    color: #fff;
}

.buttonGlobalMain span {
    display: flex;
    flex-wrap: wrap;
}

.half {
    width: 50%;
}

.quarter {
    width: 33%;
}

.auto {
    width: auto;
    display: inline-flex;
}

.buttonGlobalMain.smallRound button {
    width: 32px;
    height: 32px;
    padding: 3px;
}

.buttonGlobalMain.smallest button {
    padding: 0 15px;
    font-size: 14px;
    height: 36px;
}

.buttonGlobalMain.whiteBorder button {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 400;
    height: 33px;
}

.disableClass button {
    background: #E5E7EB !important;
    color: rgba(107, 114, 128, 0.5);
    pointer-events: none;
    opacity: 0.5;
}

.primary button {
    background: #5F6369;
}

.secondary button {
    border: 1px solid #5F6369;
    background: none;
    color: #2A2D32
}

.buttonGlobalMain.tertiary button {
    background: rgba(0, 0, 0, 0.2);
    color: #2A2D32;
}

.small button {
    height: 35px;
    font-size: 14px;
    line-height: 16px;
}

.green button {
    background: #3d5ee1;
    color: #fff;
}

.gray button {
    background: #E5E7EB;
    color: #6B7280;
}

.border button {
    background: none;
    color: rgb(63, 44, 232);
    border: 1px solid #3D2AE7;
}

.white button {
    background: #fff;
    color: #2A2D32;
}

.white button:hover {
    color: #fff;
}

.white button:hover img {
    filter: brightness(0) invert(1);
}

.red button {
    background: #f44336;
    color: #fff;
}

.medium button {
    border-radius: 50px;
}

.right button {
    flex-direction: row-reverse;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.right button span {
    display: inline-block;
    padding-right: 20px;
    position: relative;
}

.right button b {
    max-width: 16px;
    width: 100%;
    max-height: 16px;
    margin-left: 8px;
}

.right button span b {
    position: absolute;
    right: -7px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}

.left button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.left button b {
    max-width: 16px;
    width: 100%;
    max-height: 16px;
    margin-right: 8px;
    display: inline-flex;
}

.left button span {
    display: inline-block;
    padding-left: 20px;
    position: relative;
}

.left button span b {
    position: absolute;
    left: -7px;
    top: 0px;
    bottom: 0px;
    margin: auto;
}

.buttonGlobalMain.default button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(132, 90, 223, 0.16);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
}

.buttonGlobalMain.default button svg {
    color: #845ADF
}

.buttonGlobalMain.yesDelete button {
    background: #ef4444;
}

.buttonGlobalMain.yesDelete.disableClass button {
    background: #E5E7EB;
}

.buttonGlobalMain.detail button span {
    display: none;
}

.buttonGlobalMain.detail button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(132, 90, 223, 0.16);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 0px;
    background-image: url("../../../public/images/sidebar/eye.png");
    background-position: center center;
    background-repeat: no-repeat;
}

.buttonGlobalMain.edit button span {
    display: none;
}

.buttonGlobalMain.edit button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(254, 180, 36, 0.16);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 0px;
    background-image: url("../../../public/images/sidebar/edit.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(254, 180, 36, 0.16);
}

.buttonGlobalMain.delete button span {
    display: none;
}

.buttonGlobalMain.delete button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(16, 185, 129, 0.16);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 0px;
    background-image: url("../../../public/images/sidebar/del.png");
    background-position: center center;
    background-repeat: no-repeat;
}


.buttonGlobalMain.close button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(254, 10, 10, 0.50);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 0px;
}


.buttonGlobalMain.close button span b svg {
    color: #FE0A0A;
    font-size: 20px;
}

.buttonGlobalMain.orange button {
    background: #FF6F1B;
}


.buttonGlobalMain.config button span {
    display: none;
}

.buttonGlobalMain.config button {
    width: 30px;
    max-width: 30px;
    min-width: auto;
    height: 30px;
    background-color: rgba(75, 75, 75, 0.16);
    padding: 0px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 0px;
    background-image: url("../../../public/images/sidebar/config.png");
    background-position: center center;
    background-repeat: no-repeat;
}

.buttonGlobalMain.pdf button{
    background-color: rgba(254, 180, 36, 0.16)!important;
    padding: 2px;
}

.buttonGlobalMain.pdf button svg{
   color: #FEC75D; 
}



.buttonGlobalMain.arrowRight button {
    padding-right: 40px;
    background-image: url("../../../public/images/arrowRight2.png");
    background-position: 96% center;
    background-repeat: no-repeat;
}


@media screen and (max-width:600px) {
.small button{ padding: 0 13px; font-size: 12px; height: 32px;}
}